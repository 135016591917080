exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-coffee-calculator-js": () => import("./../../../src/pages/coffeeCalculator.js" /* webpackChunkName: "component---src-pages-coffee-calculator-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-dme-calculator-js": () => import("./../../../src/pages/dmeCalculator.js" /* webpackChunkName: "component---src-pages-dme-calculator-js" */),
  "component---src-pages-dough-calculator-js": () => import("./../../../src/pages/doughCalculator.js" /* webpackChunkName: "component---src-pages-dough-calculator-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-recipes-bagels-js": () => import("./../../../src/pages/recipes/bagels.js" /* webpackChunkName: "component---src-pages-recipes-bagels-js" */),
  "component---src-pages-recipes-home-oven-pizza-js": () => import("./../../../src/pages/recipes/homeOvenPizza.js" /* webpackChunkName: "component---src-pages-recipes-home-oven-pizza-js" */),
  "component---src-pages-recipes-js": () => import("./../../../src/pages/recipes.js" /* webpackChunkName: "component---src-pages-recipes-js" */),
  "component---src-pages-recipes-neapolitan-pizza-js": () => import("./../../../src/pages/recipes/neapolitanPizza.js" /* webpackChunkName: "component---src-pages-recipes-neapolitan-pizza-js" */),
  "component---src-pages-recipes-pizza-sauce-js": () => import("./../../../src/pages/recipes/pizzaSauce.js" /* webpackChunkName: "component---src-pages-recipes-pizza-sauce-js" */),
  "component---src-pages-tools-js": () => import("./../../../src/pages/tools.js" /* webpackChunkName: "component---src-pages-tools-js" */),
  "component---src-templates-blog-template-js": () => import("./../../../src/templates/blogTemplate.js" /* webpackChunkName: "component---src-templates-blog-template-js" */)
}

